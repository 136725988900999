<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir" [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'dark': dark,  'horizontal' : horizontal }">
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="warning" class="topbar telative">
        <div class="w-100 fix-width-for-horizontal">
            <!-- ============================================================== -->
            <!-- Logo - style you can find in header.scss -->
            <!-- ============================================================== -->
            <div class="navbar-header">
                <a class="navbar-brand">
                    <span fxShow="false" fxShow.gt-xs style="margin-left: 10px;">
						<img [src]="FileUrlLogo" class="light-logo" alt="inicio" style="height: 55px; margin: 0 auto;" fxFlex="100">
                    </span>
                </a>
            </div>
            <!-- ============================================================== -->
            <!-- sidebar toggle -->
            <!-- ============================================================== -->

            <button mat-icon-button (click)="snav.toggle() && clickEvent()" class="sidebar-toggle" value="sidebarclosed">
				<mat-icon>menu</mat-icon>
			</button>
            <!-- ============================================================== -->
            <!-- Search - style you can find in header.scss -->
            <!-- ============================================================== 
            <button mat-raised-button (click)="busqueda('',{})" color="primary"><mat-icon> search</mat-icon> Buscar </button>
            -->
            <span fxFlex="100" class="text-themecolor" style="margin-left: 10px;">Administración</span>
            <!-- ============================================================== -->
            <!-- app header component - style you can find in header.scss / header.component.ts-->
            <!-- ============================================================== -->
            <div *ngIf="!horizontal else horizontalheader">
                <app-vertical-header></app-vertical-header>
            </div>

            <ng-template #horizontalheader>
                <app-horizontal-header></app-horizontal-header>
            </ng-template>

            <!-- ============================================================== -->
            <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
            <!-- ============================================================== -->


        </div>

    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0" [ngClass]="{'minisidebar': minisidebar}">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true" (close)="sidebarOpened = false">
            <perfect-scrollbar *ngIf="!horizontal; else horizontalsidebar" class="scbar" [config]="config">
                <app-vertical-sidebar class="app-sidebar"></app-vertical-sidebar>
            </perfect-scrollbar>
            <ng-template #horizontalsidebar>
                <app-horizontal-sidebar></app-horizontal-sidebar>
            </ng-template>
        </mat-sidenav>
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
            <div class="scroll">
                <mat-nav-list>

                    <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>
                    <mat-list-item *ngIf="!minisidebar">
                        <mat-slide-toggle color="warn" [(ngModel)]="horizontal">
                            Horizontal Layout</mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-slide-toggle color="warn" (change)="dir = (dir == 'rtl' ? 'ltr' : 'rtl')">RTL
                        </mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item *ngIf="!horizontal">
                        <mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>
                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="danger" (change)="green = blue = false" class="text-danger">Red
                        </mat-checkbox>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="green" (change)="danger = blue = false" class="text-megna">Teal Green
                        </mat-checkbox>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="blue" (change)="green = danger = false" class="text-info">Blue
                        </mat-checkbox>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="dark" (change)="green = blue = danger = false">Dark
                        </mat-checkbox>
                    </mat-list-item>
                </mat-nav-list>
            </div>
        </mat-sidenav>

        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">

            <!--<app-breadcrumb></app-breadcrumb> -->
            <div class="page-content">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>

            </div>
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>