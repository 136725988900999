import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';

export const AppRoutes: Routes = [    
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    }, 
    {
        path: '',
        component: FullComponent,
        pathMatch: 'prefix',
        children: [
            {
                path: 'colaborador',
                loadChildren: () => import('./Colaborador/colaborador.module').then(m => m.ColaboradoresModule)
            },
            {
                path: 'proveedores',
                loadChildren: () => import('./Proveedor/proveedor.module').then(m => m.ProveedorModule)
            },
            {
                path: 'catalogos',
                loadChildren: () => import('./Catalogo/catalogo.module').then(m => m.CatalogoModule)
            },
            {
                path: 'estructura',
                loadChildren: () => import('./Estructura/estructura.module').then(m => m.EstructuraModule)
            },
            {
                path: 'roles',
                loadChildren: () => import('./RolesPermisos/roles.module').then(m => m.RolesModule)
            },
            {
                path: 'empresa',
                loadChildren: () => import('./Empresa/empresa.module').then(m => m.EmpresaModule)
            },
            {
                path: 'centroscostos',
                loadChildren: () => import('./CentroCostos/centrocostos.module').then(m => m.CentroCostosModule)
            },
            {
                path: 'reglas',
                loadChildren: () => import('./Reglas/reglas.module').then(m => m.ReglasModule)
            },
        ],
    },
    {
        path: 'login',
        loadChildren: () => import('./Login/login.module').then(m => m.LoginModule)
        //component: LoginComponent
    },
];
