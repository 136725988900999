import { Injectable } from '@angular/core';
import { TokenService } from '../../auth/token/token.service';

export interface BadgeItem {
	type: string;
	value: string;
}
export interface Saperator {
	name: string;
	type?: string;
}
export interface SubChildren {
	state: string;
	name: string;
	type?: string;
}
export interface ChildrenItems {
	state: string;
	name: string;
	type?: string;
	child?: SubChildren[];
}

export interface Menu {
	state: string;
	name: string;
	type: string;
	icon: string;
	imagen: string;
	perm: string;
	badge?: BadgeItem[];
	saperator?: Saperator[];
	children?: ChildrenItems[];
}

const MENUITEMS = [
	{
		state: 'empresa',
		name: 'Clientes',
		type: 'link',
		icon: 'content_copy',
		imagen: 'assets/images/iconos/empresas_b.png',
		perm: 'vem'
	},
	{
		state: 'proveedores',
		name: 'Proveedores',
		type: 'link',
		icon: 'content_copy',
		imagen: 'assets/images/iconos/proveedores_b.png',
		perm: 'vpr'
	},
	{
		state: 'colaborador',
		name: 'Colaboradores',
		type: 'link',
		icon: 'content_copy',
		imagen: 'assets/images/iconos/staff_b.png',
		perm: 'vco'
	},
	{
		state: 'roles',
		name: 'Roles y permisos',
		type: 'link',
		icon: 'content_copy',
		imagen: 'assets/images/iconos/rolespermisos_b.png',
		perm: 'vrp'
	},
	{
		state: 'centroscostos',
		name: 'Centros de Costos',
		type: 'link',
		icon: 'content_copy',
		imagen: 'assets/images/iconos/centros_b.png',
		perm: 'vcc'
	},
	{
		state: 'estructura',
		name: 'Estructura Org.',
		type: 'link',
		icon: 'content_copy',
		imagen: 'assets/images/iconos/estructura_b.png',
		perm: 'veo'
	},
	{
		state: 'catalogos',
		name: 'Catálogos',
		type: 'link',
		icon: 'content_copy',
		imagen: 'assets/images/iconos/catalogos_b.png',
		perm: 'vct'
	},
	{
		state: 'reglas',
		name: 'Reglas gastos',
		type: 'link',
		icon: 'content_copy',
		imagen: 'assets/images/iconos/reglas_b.png',
		perm: 'vrg'
	},
];

@Injectable()
export class MenuItems {

	constructor(
		private tokenService: TokenService
	){}

	getMenuitem(): Menu[] {
		var rls: any = this.tokenService.getTokenVar('rls');
		var permisos = rls.split(',').filter((r: any)=>{
			return r.split(':')[1] == 'ADMON';
		});
		if(permisos.length > 0){
			var acc: any[] = [];
			permisos.map((r: any)=>{
				acc = acc.concat(r.split(':')[3].split('|'));
			});
			return MENUITEMS.filter((m: any)=> acc.includes(m.perm));
		}else{
			return [];
		}
	}
}
