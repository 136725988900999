import { Injectable } from '@angular/core';
import { HttpEvent, HttpClient, HttpHeaders, HttpResponse, } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Observer } from 'rxjs';
import { TokenService } from '../auth/token/token.service';

const API_URL = environment.ApiUrl;

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private http: HttpClient, 
		private tokenService: TokenService
	) { }

	authenticate(userName: string, password: string) {
		return this.http.post(API_URL + 'auth/login', {
			userName: userName,
			password: password,
		});
	}

	activate(userName: string, password: string, token: string) {
		console.log("ACTIVANDO...")
		return new Observable((observer: Observer<HttpEvent<any>>) => {
			const request = new Request(API_URL + 'auth/finalize', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
				body: JSON.stringify({
					'userName': userName,
					'password': password,
				})
			});
			fetch(request).then((r) => {
				r.json().then((body) => {
					const headers = new HttpHeaders();
					r?.headers.forEach((value, name) => {
						headers.append(name, value);
					});
					observer.next(
						new HttpResponse({
							url: r.url,
							status: r.status,
							statusText: r.statusText,
							body: body,
							headers: headers,
						})
					);
				});
			});
		});
	}

	resetPassword(password: string, token: string) {
		return new Observable((observer: Observer<HttpEvent<any>>) => {
			const request = new Request(API_URL + 'auth/resetpassword', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
				body: JSON.stringify({
					'password': password,
				})
			});
			fetch(request).then((r) => {
				r.json().then((body) => {
					const headers = new HttpHeaders();
					r?.headers.forEach((value, name) => {
						headers.append(name, value);
					});
					observer.next(
						new HttpResponse({
							url: r.url,
							status: r.status,
							statusText: r.statusText,
							body: body,
							headers: headers,
						})
					);
				});
			});
		});
	}

	sendEmailReset(data: string) {
		return new Observable((observer: Observer<HttpEvent<any>>) => {
			const request = new Request(API_URL + 'email/resetpassword', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: data
			});
			fetch(request).then((r) => {
				r.json().then((body) => {
					const headers = new HttpHeaders();
					r?.headers.forEach((value, name) => {
						headers.append(name, value);
					});
					observer.next(
						new HttpResponse({
							url: r.url,
							status: r.status,
							statusText: r.statusText,
							body: body,
							headers: headers,
						})
					);
				});
			});
		});
	}

	refresh(token: string) {
		return this.http.post(API_URL + 'auth/refresh', { token: token });
	}

	logout() {
		this.tokenService.removeToken();
		this.tokenService.removeUser();
		this.tokenService.removePermisos();
		window.location.href = '/login';
	}
}
