import { Component } from '@angular/core';
import IdleTimer from "./Genericos/idle.timer";
import { TokenService } from './auth/token/token.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    timer: any;
    constructor( private tokenService: TokenService, ) {}
    ngOnInit() {
        this.timer = new IdleTimer(
            3000, //expired after 300 secs
            () => {
                this.closeSession();
                this.timer.cleanUp();
            }
        );
    }
    ngOnDestroy() {
        this.timer.cleanUp();
    }

	closeSession(){
		this.tokenService.removeToken();
		this.tokenService.removeUser();
		this.tokenService.removePermisos();
		window.location.href = 'https://www.zzas.com.mx';
	}
}
