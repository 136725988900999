
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { historial } from '../../_interfaces/interfaces';
import { TokenService } from '../../auth/token/token.service'; 

const API = environment.ApiUrl;
const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }) 
};

const httpOptions2 = {
    headers: new HttpHeaders({
      'Accept': 'application/pdf',
      'Content-Type': 'application/json' // We send JSON
    }),
    responseType: 'blob' as 'json'  // We accept plain text as response.
};

const httpOptions3 = {
    headers: new HttpHeaders({
      'Accept': 'image/*',
      'Content-Type': 'application/json' // We send JSON
    }),
    responseType: 'blob' as 'json'  // We accept plain text as response.
};

@Injectable({
    providedIn: 'root'
})
export class serviciosService {
    
    constructor(
        private http: HttpClient,        
		private tokenService: TokenService,
    ) { }
    
    public get(url:string, id:string) {      
        return this.http.get<any>(`${API}${url}/${id}`,httpOptions)
    }

    public put(url:string, arr:any) {
        return this.http.put<any>(`${API}${url}`, arr, httpOptions)
    }

    public post(url:string, arr:any) {
        return this.http.post<any>(`${API}${url}`, arr, httpOptions)
    }

    public postDocument(url:string, arr:any) {
        return this.http.post<any>(`${API}${url}`, arr)
    }

    public postDatosQ(url:string, id:any, arr:any) {
        return this.http.post<any>(`${API}${url}/${id}`,arr , httpOptions)
    }

    public patch(url:string, arr:any) {
        return this.http.patch<any>(`${API}${url}`, arr, httpOptions)
    }

    public delete(url:string, arr:any) {
        return this.http.delete<any>(`${API}${url}${arr}`)
    }

    public getFile(url:string, id:string ): Observable<Blob> {   
        return this.http.get<Blob>(`${API}${url}/${id}`, httpOptions2);              
    }

    public getImage(url:string, id:string ): Observable<Blob> {   
        return this.http.get<Blob>(`${API}${url}/${id}`, httpOptions3);              
    }

    public sendEmailNotification(para: string, nombre: string, asunto: string, tituloEmail: string, parrafo1: string, parrafo2: string, idEmpresa: number){
        var data = {
            "para": para,
            "nombre": nombre,
            "asunto": asunto,
            "tituloEmail": tituloEmail,
            "parrafo1": parrafo1,
            "parrafo2": parrafo2,
            "idEmpresa": idEmpresa
        };
        return this.post('email/notificacion', data);
    }

    public history(element: historial){
        element.idUser = this.tokenService.getTokenVar('id');
        element.fechaRegistro = new Date().toISOString().replace('Z','').replace('T',' ').split('.')[0];
        return this.post('catalogo/historial', element);
    }
}