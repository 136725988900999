import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from '../../../auth/token/token.service';

@Component({
	selector: 'app-vertical-header',
	templateUrl: './vertical-header.component.html',
	styleUrls: []
})

export class VerticalAppHeaderComponent {
	public config: PerfectScrollbarConfigInterface = {};
	userName: any = '';

	// This is for Notifications
	notifications: Object[] = [
		{
			round: 'round-danger',
			icon: 'ti-link',
			title: 'Launch Admin',
			subject: 'Just see the my new admin!',
			time: '9:30 AM'
		},
	];

	// This is for Mymessages
	mymessages: Object[] = [
		{
			useravatar: 'assets/images/users/1.jpg',
			status: 'online',
			from: 'Pavan kumar',
			subject: 'Just see the my admin!',
			time: '9:30 AM'
		},
	];

	public selectedLanguage: any = {
		language: 'Español',
		code: 'es',
		type: 'Es',
		icon: 'mx'
	}

	public languages: any[] = [{
		language: 'English',
		code: 'en',
		type: 'US',
		icon: 'us'
	},
	{
		language: 'Español',
		code: 'es',
		icon: 'mx'
	},]

	constructor(
		private translate: TranslateService,
		private tokenService: TokenService,
	) {
		translate.setDefaultLang('es');
		this.userName = this.tokenService.getUser();
	}

	changeLanguage(lang: any) {
		this.translate.use(lang.code)
		this.selectedLanguage = lang;
	}

	closeSession(){
		this.tokenService.removeToken();
		this.tokenService.removeUser();
		this.tokenService.removePermisos();
		window.location.href = '/login';
	}
}
