<!-- Breadcrumb -->

<div class="page-breadcrumb p-t-20 p-b-20 p-l-30 p-r-30">
	<div fxLayout="row wrap">
        <div fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="100" class="align-self-center">
            <h3 class="page-title text-themecolor m-0"><b>{{pageInfo?.title}}</b></h3>
		</div>
		<div fxFlex.gt-sm="40" fxFlex.gt-xs="40" fxFlex="100" class="align-self-center">
            <div class="breadcrumb d-flex align-items-center float-right float-sm-left">
                <ul class="list-style-none d-flex align-items-center">
                    <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
                        <!-- 
                        <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                            <a href='javascript:void(0)'>{{url.title}}</a>
                        </li>
                        <li class="breadcrumb-item active" *ngIf="last">{{url.title}}</li> -->
                        <li class="breadcrumb-item " *ngIf="last"><button mat-raised-button (click)="openDialog('Add',{})" color="primary">Add Employee</button></li>
                        <li class="breadcrumb-item " *ngIf="last"><img [src]='url.icono'class="img-icono" /></li>
                    </ng-template>
                </ul>
            </div>
        </div>
    </div>
</div>