
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Location } from "@angular/common";
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { TokenService } from '../../auth/token/token.service';
import { serviciosService } from '../../Genericos/servicios/servicios.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: FormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const isSubmitted = form && form.submitted;
		return !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched || isSubmitted)
		);
	}
}

/** @title Responsive sidenav */
@Component({
	selector: 'app-full-layout',
	templateUrl: 'full.component.html',
	styleUrls: ['full.component.scss']
})
export class FullComponent implements OnInit, OnDestroy {
	message: any;
	subscription!: Subscription;
	mobileQuery: MediaQueryList;
	dir = 'ltr';
	green = false;
	blue = true;
	dark = false;
	minisidebar = false;
	boxed = false;
	danger = false;
	showHide = false;
	horizontal = false;
	url = '';
	sidebarOpened = false;
	status = false;
	ruta = '';
	empresaId: number = 0;
	FileUrlLogo: any = 'assets/images/logo-light-text.png';

	public showSearch = false;
	public config: PerfectScrollbarConfigInterface = {};
	private _mobileQueryListener: () => void;

	clickEvent() {
		this.status = !this.status;
	}
	constructor(
		public router: Router,
		changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
		public menuItems: MenuItems,
		location: Location,
		private tokenService: TokenService,
        private serviciosService: serviciosService,
		private domSanitizer: DomSanitizer,
	) {
		this.mobileQuery = media.matchMedia('(min-width: 1023px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		// tslint:disable-next-line: deprecation
		this.mobileQuery.addListener(this._mobileQueryListener);

		router.events.subscribe(val => {
			if (location.path() != "") {
				this.ruta = location.path();
			} else {
				//this.ruta = "Home";
			}
		});
		
		var emp: any = this.tokenService.getTokenVar('rls');
		this.empresaId = emp.split(',').map((r: any)=>{
				return Number(r.split(':')[0]);
			})[0];
		if(this.empresaId > 0)
			this.downloadLogo();
	}

	ngOnInit() {
	}

	ngOnDestroy(): void {
		// tslint:disable-next-line: deprecation
		this.mobileQuery.removeListener(this._mobileQueryListener);
		this.subscription.unsubscribe();
	}

	downloadLogo(){
		this.serviciosService.get('catalogo', '?catalogo=empresas&filtro1='+encodeURIComponent('id='+this.empresaId))
			.subscribe((emp: any) => {
				if(emp[0].logoUrlPublica)
					this.FileUrlLogo = emp[0].logoUrlPublica+'?'+String(Date.now());
				else
					this.FileUrlLogo = 'assets/images/logo-light-text.png';
			},
			(err:any) => {
				console.log(err);
				this.FileUrlLogo = 'assets/images/logo-light-text.png';
			 }, 
			() => { }); 
	}
}
