import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";

const KEY = 'authToken';
const KEY_USER = 'authUser';
const PER_USER = 'authPerm';

@Injectable({ providedIn: 'root'})
export class TokenService {
    hasToken() {
        return !!this.getToken();
    }

    setToken(token: string) {
        window.localStorage.setItem(KEY, token);
        //var decoded: any = jwt_decode(token);
        //console.log(decoded);
    }

    getToken() {
        var token =  window.localStorage.getItem(KEY) || '';
        //var decoded: any = jwt_decode(token);
        //console.log(decoded);
        return token;
    }

    getTokenVar(varName: string){
        var token: any = window.localStorage.getItem(KEY);
        var decoded: any = jwt_decode(token);
        return decoded[varName];
    }

    isTokenExpired(){
        var exp = this.getTokenVar('exp');
        return Date.now() >= exp * 1000;
    }

    removeToken() {
        window.localStorage.removeItem(KEY);
    }

    setUser(user: string) {
        window.localStorage.setItem(KEY_USER, user);
    }

    getUser() {
        return window.localStorage.getItem(KEY_USER);
    }

    removeUser() {
        window.localStorage.removeItem(KEY_USER);
    }

    setPermisos(per: any) {
        window.localStorage.setItem(PER_USER, JSON.stringify(per));
    }

    getPermisos() {
        console.log(window.localStorage.getItem(PER_USER) );
        return JSON.parse(window.localStorage.getItem(PER_USER) || '');
    }

    removePermisos() {
        window.localStorage.removeItem(PER_USER);
    }
}